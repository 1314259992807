.sidebar .sidebar-brand-wrapper .sidebar-brand.brand-logo-mini img {
    width: calc(90px - 50px);
}

.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
    width: calc(90px - 50px);
}

.form-control:focus {
    color: #fff !important;
    border: 1px solid rgb(147 130 177 / 61%) !important;
}
/* .asrt-page-length select{
    background: transparent !important;
    border: 1px solid #413e47 !important;
} */
select {
    cursor: pointer;
}

select option {
    cursor: pointer;
}

input[type="search"]::-webkit-search-cancel-button {
    cursor: pointer !important;
    color: #fff !important;
}

.asrt-pagination li {
    cursor: pointer;
}

.passwordinput .form-control {
    background-color: #191c24;
}

.passwordinput .btn-outline-primary {
    color: #6e7ee5;
    border-color: #494a51;
    padding: 10px 10px;
}

.passwordinput .btn-outline-primary:hover {
    background-color: transparent !important;
}
.footer a{
    color: #886abe !important;
}
.asrt-page-length .input-group-addon{
    background: unset !important;
}
.asrt-page-length select{
    color: #fff !important;
}
.input-group-prepend span{
    color: #fff !important;
}
.asrt-table-foot{
    margin-top: 2%;
}
.page-item.disabled .page-link {
    color: #9382b1 !important;
    pointer-events: none;
    cursor: auto;
    background-color: #fff0 !important;
    border-color: #9382b1 !important;
}
.page-item.disabled .page-link:hover{
    background-color: #886abe !important;
    color: #fff !important;
}
.as-react-table .pagination li:hover a {
    background-color: #886abe !important;
    color: #fff!important;
}
.page-link {
    background-color: #00000000 !important;
    border: 1px solid #9382b1;
}
.page-link input{
    background: unset !important;
    color: #fff !important;
}
.allbtn{
    background: #886abe;
    border: 1px solid #886abe;
    padding: 10px 16px;
}
.table thead th{
   line-height: 21px;
}
.allbtnlogout{
    background: red;
    border: 1px solid red;
    padding: 6px 14px;
    color: #fff;
    border-radius: 6px;
}
.allbtnlogout:hover{
    background: transparent;
    border: 1px solid red;
    color: red;
}
.allbtns{
    padding: 8px 16px !important; 
}
.allbtn:hover{
    background: transparent;
    border: 1px solid #886abe;
    color: #886abe !important;
}
.icon.icon-box-success {
    background: rgb(130 101 182 / 24%) !important;
}
.icon .icon-item {
    color: #9768ec;
}
.ReactModal__Overlay {
    background-color: #00000091 !important;
}
.ReactModal__Content{
    border: 1px solid #808080a8 !important;
    background: rgb(11 12 15) !important;
    border-radius: 16px !important;
}
.ReactModal__Content h2{
    color: rgb(136 106 190) !important;
}
.closebtn{
    position: absolute;
    right: 8px;
    top: 4px;
    font-size: 19px;
    font-weight: 500;
}
.closebtn:hover{
   color: #886abe;
}
.table-responsive{
    padding-bottom: 2%;
    overflow-x: auto !important;
}
/* .asrt-table-body {
    overflow: auto;
    padding-bottom: 2%;
} */
.table-responsive::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar
{
	width: 8px !important;
    height: 9px !important;
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar-thumb
{
    border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #886abe;
}
.faq .as-react-table{
    padding-bottom: 2%;
    overflow-x: auto !important;
}
.faq .as-react-table::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.faq .as-react-table::-webkit-scrollbar
{
	width: 8px !important;
    height: 9px !important;
	background-color: #F5F5F5;
}

.faq .as-react-table::-webkit-scrollbar-thumb
{
    border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #886abe;
}
.sidebar .nav .nav-item .nav-link i.menu-arrow:before {
  display: none;
}
.dash-token{
    color: #886abe !important;
}
.editservice{
    font-size: 15px;
}
.sidebar .nav.sub-menu .nav-item .nav-link {
    white-space: normal;
    line-height: 20px;
}
/* th.sortable {
    color: #886abe !important;
    font-weight: 600 !important;
} */


@media only screen and (max-width: 767px) {
     .table-responsive::-webkit-scrollbar
{
	width: 6px !important;
    height: 6px !important;
	background-color: #F5F5F5;
}
.faq .as-react-table::-webkit-scrollbar
{
	width: 6px !important;
    height: 6px !important;
	background-color: #F5F5F5;
}
    .table-responsive{
        padding-bottom: 8%;
    }
    .faq .as-react-table{
        padding-bottom: 8%;
    }
    .pagination {
        justify-content: unset !important;
    }
    .pagination li{
        font-size: 11px !important;
        margin-top: 10px;
    }
    .table_filter input{
       margin-top: 14px;
    }
   .asrt-page-length .input-group-text{
       font-size: 11px;
    }
    .asrt-page-length select.form-control {
        padding: 0.4375rem 0.45rem !important;
        width: 53px !important;
        font-size: 12px !important;
    }
    .asrt-table-head .text-right{
        text-align: left !important;
    }
    .asrt-table-foot>div:first-child {
       font-size: 12px;
       margin-top: 6px;
    }
  }
  .navbar.loginnavbar{
    left: 0px !important;
}
.btn-primary:not(.btn-light):not(.btn-secondary):focus, .custom-file .custom-file-label:not(.btn-light):not(.btn-secondary):focus:after, .btn-primary:not(.btn-light):not(.btn-secondary):active, .custom-file .custom-file-label:not(.btn-light):not(.btn-secondary):active:after {
    background: #886abe !important;
    border-color: #886abe !important;
    color: #fff !important;
}
.drops_dropdown button{
    border: none;
    background-color: #2A3038 !important;
    padding: 10px 30px;
    box-shadow: none !important;
}
.modal_calo{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cal_holder{
    width: 70%;
}
.modal_calo .rdtPicker{
    background-color: transparent !important;
    width: 100%;
    border: none;
}
.modal_calo .rdtPicker tfoot,.modal_calo .rdtPicker th{
    border: none !important;
}
.modal_calo th.dow:hover,.modal_calo td.rdtDay:hover,.modal_calo .rdtPrev:hover,.modal_calo .rdtNext:hover,.modal_calo .rdtSwitch:hover,.modal_calo .rdtTimeToggle:hover,.modal_calo .rdtBtn:hover{
    background-color: #886abe !important;
}
.modal_calo .rdtCounter .rdtBtn{
    height: 35%;

}
.done_btn{
    border: 1px solid #886abe;
    outline: none;
    background-color: #886abe;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    border-radius: 5px;
    padding: 5px 20px;
    position: relative;
}
.done_btn:hover{
    background-color: transparent;
    border: 1px solid #886abe;
    color: #886abe;
}
.modal_closer{
    color: #fff;
    position: relative;
    right: 10px;
    top: 10px;
    opacity: 1;
}
.modal_closer:hover{
    color: #fff;
}
.bottom_tripleBtns{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.profileImg_holder{
    width: 70%;
    min-height: 250px;
    height: 250px;
    max-height: 250px;
    border: 1px solid #2A3038;
    padding: 15px;
    border-radius: 10px;
    position: relative;
}
.selected_profImg{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.pencil_holder{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #886abe;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translate(-50%,-50%);
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile_img_file{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    cursor: pointer;
}
.lazy_logo_align{
    border-radius: 10px;
}
.done_btn .spinner-border{
    width: 1rem;
    height: 1rem;
    margin-right: 5px;
}
.common_inpHolder{
    width: 100%;
    height: 40px;
    background-color: #2A3038;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
}
.commn_fileInp{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
}
.inp_done_btn.done_btn{
    height: 40px;
}
.validateModal_btn{
    opacity: 0;
    display: none;
}
.fileindees{
    border: none;
    outline: none;
    background-color: transparent;
    color: #fff;
    
}